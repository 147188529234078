<template>
  <div class="newsDetail">
    <TopNav></TopNav>
    <!-- 背景开始 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>新闻动态</div>
            <div>
              用中标资讯、运营干货一键掌握
            </div>
          </div>
        </div>
      </div>
      <div class="top-bg-content-phone">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>番茄新闻动态</div>
            <div>
              新闻动态抢先看，从权益输出到品牌赋能
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景结束 -->

    <!-- 详情全部开始 -->
    <div class="contentDetail">
      <p class="detail-nav">
        <a @click="goback">新闻动态></a>
        <span class="title">
          案例解析丨中国邮政储蓄银行邮储闪光卡开学季幸运拆福袋活动
        </span>
      </p>
      <p class="detail-title">
        案例解析丨中国邮政储蓄银行邮储闪光卡开学季幸运拆福袋活动
      </p>
      <p class="detail-time">
        <span>2021-11-26</span>
      </p>
      <i class="time-border"></i>
      <div class="detail-all">
        <p>
          移动互联网浪潮后，用户对传统营销方式已经产生“抗体”。因此，各大银行纷纷开启数字化转型，橙券为银行数字化运营提供了一种新思路：通过游戏化营销，激活沉睡用户，提升用户活跃度，加快银行数字化转型进程。
        </p>
        <p>
          <img src="../assets/image/bannarImg1.png" alt="" />
        </p>
        <p>
          <strong>·以游戏促进用户活跃</strong>
          <br />
          <strong>橙券探索游戏化营销新思路</strong>
        </p>
        <p>
          移动互联网浪潮后，用户对传统营销方式已经产生“抗体”。因此，各大银行纷纷开启数字化转型，橙券为银行数字化运营提供了一种新思路：通过游戏化营销，激活沉睡用户，提升用户活跃度，加快银行数字化转型进程。
        </p>
        <p>
          <img src="../assets/image/bannarImg1.png" alt="" />
        </p>
      </div>
      <div class="detail-page">
        <span class="left">上一篇: 特惠礼品怎么选择才能为营销锦上添花？</span>
        <span class="right">下一篇: 员工福利设计，可千万别只图个仪式感</span>
      </div>
    </div>
    <!-- 详情全部结束 -->

    <Bottom></Bottom>
  </div>
</template>

<script>
import "../assets/less/newsDetail.less";
import TopNav from "../components/topNav";
import Bottom from "../components/Bottom";
export default {
  data() {
    return {};
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
  components: {
    TopNav,
    Bottom,
  },
};
</script>

<style lang="less" scoped></style>
